@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}


.newsbannersec {
    padding: 45px 0 75px;
    background: rgba(30, 30, 38, 1);
}

.newsbannersec .headingRout {
    color: #fff;
}

.newsbannersec .headingRout span {
    color: #fff;
}

.newsbanner_part {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newsbanner_part h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    margin-bottom: 0;
    max-width: 450px;
    padding-right: 15px;
}

.newsbanner_part figure { 
    width: 46%;
    margin-bottom: 0;
}
.newsbanner_part figure  img {
    width: 100%;
}



.Mainnews_section {
    margin: 56px 0 112px;
}


.container {
    max-width: 1230px;
}



.containerBox {
    padding-left: 0;
    margin-bottom: 0;
}

.containerBox li {
    border: 1px solid #D2D2D4;
    border-radius: 16px;
    position: relative;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
}


.containerBox li+li {
    margin-top: 50px;
}

.contentBox {
    padding: 32px 0px 32px 32px;
    position: relative;
    max-width: 617px;
    margin-right: 20px;
}

.contentBox h4 {
    font-size: 24px;
    font-weight: 700;
    color: #1E1E26;
    margin-bottom: 24px;
}

.contentBox p {

    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #4B4B51;
    margin-bottom: 32px;

}

.containerBox li .imageBox {
    max-width: 581px;
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 40px 0;

}

.imageBox img {
    width: 450px;
    border-radius: 15px;
    object-fit: fill;
    transform: translateX(-20px);
}

.containerBox .ReadMoreBtn {
    color: #0A8C44;
    font-size: 18px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    padding-right: 20px;
}

.containerBox .ReadMoreBtn > img {
    margin: 0 0 0 5px;
    display: inline-block;
}



@media(max-width:991px) { 
    
    


}



@media(max-width:991px) {
    .headingRout {
        margin-bottom: 15px;
    }
    .containerBox{
        /* padding-right: 60px; */
    }
    .containerBox li {
        flex-wrap: wrap;
        padding: 25px 15px 15px;
    }

    .contentBox {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        padding: 0;
    }

    .containerBox li .imageBox {
        max-width: 100%;
        /* margin-top: 20px; */
        margin: 0;
        padding: 20px 0;
    }

    .containerBox li .imageBox img {
        height: 100%;
        border-radius: 15px;
        transform: translateX(0px); 
    
    }

    .contentBox h4 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .contentBox p {
        margin-bottom: 10px;
    }

    .Mainnews_section {
        margin: 15px 0 50px;
    }

    .containerBox li+li {
        margin-top: 30px;
    }

    .newsbanner_part {
        flex-wrap: wrap;
    }

    .newsbanner_part h1 {
        margin-top: 15px;
        font-size: 25px;
        line-height: 40px;
        width: 100%;
        text-align: center;
        max-width: 100%;
        padding-right: 0;
    }

    .newsbanner_part figure{
        width: 100%;
        margin-top: 10px;
    }
    .imageBox {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; 
        width: 100%; 
        max-height: 450px;
        border-radius: 15px;
        margin-left: 20px;
    }
    
    .imageBox img {
        width: 100%;
        height: 100%;
    }


}
