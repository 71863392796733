

.news_section {
    padding: 80px 0 150px;
    
}

.news_section h2 {
    font-size: 32px;
    color: #1E1E26;
    font-weight: bold;
    margin-bottom: 32px;
}
.imagenewsss img{
    height: 300px;
 }
.imagessss{
    object-fit:fill ;
}

.news_section ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.news_section ul li{
    width: calc(100%/3 - 20px);
    border: 1px solid #E8E8E9;
    box-shadow: 0px 5px 15px 0px #0000001F;
    border-radius: 20px;
    padding: 24px;
    position: relative;
}

.news_section ul li img{
    width: 100%;
    border-radius: 15px;
     
}

.news_section ul li p{
    font-size: 20px;
    font-weight: 400;
    color: #4B4B51;
    margin: 24px 0 35px;
    line-height: 32px;
    max-width: 100%;
}
.news_section ul li a {
    color: #0A8C44;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    /* padding-right: 20px; */
    position: absolute;
    bottom: -120px;
}

.news_section ul li a > img {
    width: 8px;
    display: inline-block;
    margin: -1px 0 0 4px;
}

@media(max-width:991px) { 
    .news_section ul li {
        padding: 15px;
        width: calc(100%/3 - 10px);
    }

    .news_section ul li p {
        font-size: 16px;
        line-height: normal;
    }



}

@media(max-width:767px) {
    .news_section {
        padding: 10px 0 0;
    }
    
    .news-list{        
        overflow-x: auto;
    }
    
    .news-list > ul {
        width: 700px;
        padding-bottom: 36px;
    } 

    .news_section h2 {
        text-align: center;
        font-size: 22px;    
        margin-bottom: 25px;
    }
    .imagessss{
        object-fit: contain;
    }

}




