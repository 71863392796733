


.glance_section {
    padding: 90px 0;
    background-color: rgb(10 140 68 / 5%);
}

.glance-part {
    display: flex;
    justify-content: space-between;
}

.glance-part .left-part { 
    width: 374px;
}
.glance-part .left-part h2 {
    font-size: 32px;
    font-weight: bold;
    color: #1E1E26;
    margin-bottom: 60px;
}

.glance-part .right-part {
    width: 57.5%;
}

.glance-part .right-part > p {
    max-width: 587px;
    font-size: 16px;
    font-weight: 400;
    color: #4B4B51;
    margin-bottom: 70px;
}

.glance-part .right-part ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.glance-part .right-part ul li {
    width: 50%;
}

.glance-part .right-part ul h3 {
    color: #292874;
    font-size: 48px;
    font-weight: bold;
}

.glance-part .right-part ul p{
    color: #78787D;
    font-size: 18px;
    font-weight: 400;
}

.glance_bg {
    /* height: 500px; */
    padding: 250px 0 ;
    background:  center center no-repeat red; 
    background-attachment: fixed;
    width: 100%;
    background-size: 100% ;
}


@media(max-width: 991px){
    .glance-part .left-part {
        width: 344px;
    }
    .glance-part .right-part {
        width: 50%;
    }
    .glance-part .right-part ul h3 {
        font-size: 40px;
    }
    .glance-part .right-part > p {
        margin-bottom: 50px;
    }  
    .glance-part .right-part ul p {
        font-size: 16px;
    }
    
    
}


@media(max-width: 767px){
    .glance_section {
        padding: 20px 0 0px;
    }    
    .glance-part {
        flex-wrap: wrap;
    }
    .glance-part .left-part {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .glance-part .left-part h2 {
        margin-bottom: 30px;
        font-size: 22px;
    }

    .glance-part .right-part {
        width: 100%;
    }
    .glance-part .right-part > p {
        font-size: 14px;
        margin-bottom: 35px;
    }
    .glance_bg {
        padding: 150px 0 0;
        /* background-attachment: scroll; */
        /* background-size: cover; */
    }

}






