

.banner_title {
    background: #1E1E26;
    padding: 30px 0 30px;
    
    text-align: center;
}

.banner_title h1 {
    color: #fff;
    font-weight: 700;
    line-height: 60px;
    font-size: 40px;
    margin: 0;    
}

.banner_title h1 span{
    position: relative;
}

.banner_title h1 span:after {
    position: absolute;
    content: "";
    left: -7px;
    bottom: -12px;
    width: 90%;
    height: 8px;
    border-radius: 1px;
    background: #0A8C44;
}

.homebanner-sec{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(10, 140, 68, 0.05);
}

.homebanner-sec .leftpart{
    width: 40%;
}
.homebanner-sec .great-place-to-work{
    text-align: center;
    padding: 15px;
}

.homebanner-sec figure {
    margin: 0;
    background: #e8f3ec;
    text-align: center;
    width: 100%;
}

.homebanner-sec .leftpart figure  img {
    max-width: 380px;
    padding: 0 15px;
    width: 100%;
}


.homebanner-sec .rightpart{
    width: 60%;
}

.homebanner-sec h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: rgba(30, 30, 38, 1);
}

.homebanner-sec .leftpart h2 {
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
}


.homebanner-sec .rightpart h2 {
    max-width: 700px;
    padding: 15px;
    margin: 0 auto;
    /* margin-bottom: 0;
    padding:15px 80px 15px 15px ; */
}















@media(max-width:992px) {
    .homebanner-sec h2 { 
        font-size: 26px;
        line-height: 36px;
    }

}




@media(max-width:767px) {

    .banner_title h1 {
        font-size: 22px;
    }

    .banner_title h1 span:after {
        height: 6px;
    }

    .banner_title {
        padding: 8px 0 22px;
    }

    .homebanner-sec .leftpart {
        width: 100%;
    }
    .homebanner-sec .rightpart{
        width: 100%;
    }
    .homebanner-sec .rightpart h2 {
        padding: 15px;
        text-align: center;
    }
    .homebanner-sec .leftpart h2{
        max-width: 100%;
        text-align:center;
    }
    
    
    
}

@media(max-width:575px) {
    .about_section{
        margin: 30px 0 50px;
    }
}


