.about_section {
  margin: 50px 0 70px;
}


.about_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about_part .left-part {
  width: 665px;
  padding-right: 15px;
}

.about_part .right-part {
  width: 381px;
}


.about_section .left-part p {
  font-size: 16px;
  color: #4B4B51;
  font-weight: 400;
  line-height: 24px;
}

.about_section ul {
  padding-left: 0;
  margin-bottom: 0;
}

.about_section ul li {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);

}

.about_section ul li + li {
  margin-top: 16px;
}

.about_section ul li h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(30, 30, 38, 1);
  margin-bottom: 0;
}

.about_section ul li p {
  font-size: 16px;
  color: rgba(75, 75, 81, 1);
  margin: 12px 0 24px;
}

.about_section ul li a {
  color: #00A095;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(10, 140, 68, 1);
  padding-right: 20px;
}

.about_section ul li a img{
  width: 7px;
  display: inline-block;
  margin: -1px 0 0 5px;
}





@media(max-width:991px) {

 

  .about_section h2 {
    font-size: 25px;
  }

  .about_part .left-part {
    width: 58%;
  }

  .about_part .right-part {
    width: 40%;
  }



}

@media(max-width: 767px) {
  .about_section {
    margin: 70px 0;
  }

  .about_part {
    flex-wrap: wrap;
  }

  .about_part .left-part {
    width: 100%;
    margin-bottom: 15px;
  }

  .about_part .right-part {
    width: 100%;
  }

  .about_section h2 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 16px;
  }

  .about_section p {
    line-height: 24px;
  }


}


@media(max-width: 575px) {
  .about_section ul {
    flex-wrap: wrap;
  }

  .about_section ul li {
    width: 100%;
    margin-bottom: 15px;
    /* padding: 10px 20px; */
    margin: 0 0;
  }

  .about_section ul li+li {
    margin-top: 10px;
  }

  .presence_box h4 {
    font-size: 25px;
  }
  .presence_box.presence_box1 {
      min-height: auto;
  }


}