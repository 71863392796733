    .overview-part{
        background-color: #F8F8F9;
        border-radius: 16px;
        padding: 32px;
        display: flex;        
        justify-content: space-between;
        border: 1px solid #E8E8E9;
    }

    .overview-part > .left_part{
        width: 44%;
        
    }

    .overview-part > .left_part .accordion-part {
        overflow-y: auto;
        overflow-x: hidden; 
        direction: rtl;
        height: 320px;
    }


    /* width */
    .overview-part > .left_part .accordion-part::-webkit-scrollbar {
        width: 3px;
    }
    
    /* Track */
    .overview-part > .left_part .accordion-part::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #E8E8E9;
    }
    
    .overview-part > .left_part .accordion-part::-webkit-scrollbar-thumb {
        background: #292974; 
        border-radius: 10px;
    }  

    .overview-part .accordion {
        direction: ltr;
        padding-left: 24px;    
    }

    .overview-part .accordion .accordion-item {
        background: transparent;
        border: none;
    }

    .overview-part .accordion .accordion-item + .accordion-item {
        margin-top: 40px;
    }

    .overview-part .accordion .accordion-item h2 { 
        background-color: transparent;
    
    }
    .overview-part .accordion .accordion-item h2  button {
        background-color: transparent;
        border: none;
        font-size: 24px;
        color: #78787D;
        font-weight: 500;
        box-shadow: none;
        padding: 0;
    }
    .overview-part .accordion .accordion-item .accordion-button:not(.collapsed) {
        border: none;  
        font-weight: bold;
        color: #1E1E26;
        outline: none;
    }

    .overview-part .accordion .accordion-item .accordion-body {
        padding: 15px 0 0 0;
        max-width: 358px;
    }
    .overview-part .accordion .accordion-item .accordion-body p {
        font-size: 16px;
        font-weight: 400;
        color: #4B4B51;
    }

    .overview-part .accordion .accordion-item .accordion-body  a{
        color: #0A8C44;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        position: relative;
        padding-right: 20px;
    }

    .overview-part > .right_part{
        width: 53.5%;
    }


    @media(max-width:991px) {
        .overview-part .accordion .accordion-item h2 button {
            font-size: 20px;
        }
        .overview-part .accordion .accordion-item + .accordion-item {
            margin-top: 28px;
        }
    }


    @media(max-width:767px) { 
        .overview-part{
            flex-wrap: wrap; 
            padding: 20px;
        }
        .overview-part > .left_part {
            width: 100%;
            margin-bottom: 37px;
        }
        .overview-part > .right_part {
            width: 100%;
        }
        .overview-part .accordion .accordion-item .accordion-body a {
            font-size: 16px;
        }
        .overview-part .accordion .accordion-item .accordion-body p {
            font-size: 14px;
        }
    }


