.conductbanner-sec {

    padding: 50px 0 135px;
    background: rgba(30, 30, 38, 1);

}


.conductbanner-sec .headingRout,
.conductbanner-sec .headingRout span {
    color: #fff;
}

.conductbanner_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
}


.conductbanner_text h1 {
    color: #fff;
    margin-bottom: 0;
    max-width: 556px;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;

} 

.conductbanner_text figure {
    margin-bottom: 0;
    max-width: 550px;
    padding-left: 10px;
    height: 404px;
}

.conductbanner_text figure img {
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
}





.banner-sec {
    margin: 56px 0 108px;
}

.banner-sec figure img {
    border-radius: 16px;
}

.bannerbox {
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
}

.bannerbox>.leftpart {
    width: 499px;
}

.bannerbox>.rightpart {
    width: 600px;
    padding-left: 15px;
}

.bannerbox h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(30, 30, 38, 1);

}

.bannerbox p {
    font-size: 16px;
    line-height: 24px;
    color: #1E1E26;
    font-weight: 400;
}

.bannerbox>.rightpart>a {
    text-decoration: none;
    color: #0A8C44;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    padding-right: 20px;

}

.bannerbox>.rightpart>a:after {
    display: inline-block;
    content: "";
    display: inline-block;
    width: 13px;
    height: 14px;
    transform: rotate(90deg);
    background-size: contain;
}

.compass-sec {
    background: #0E8C430D;
    padding: 56px 0 53px;
}

.compass-sec h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 48px;
    color: #1E1E26B2;
    margin-bottom: 0;
}

.compass-sec h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: #1E1E26;
    margin-bottom: 32px;
    max-width: 685px;
}

.compass-sec ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.compass-sec ul li {
    color: #1E1E26;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    position: relative;
}

.compass-sec ul li img {
    display: inline-block;
    vertical-align: middle;
    margin: -5px 10px 0 0px;
}

.compass-sec ul li + li {
    margin-left: 20px;
} 



.laws-sec {
    padding: 80px 0;
}

.laws-box {
    display: flex;
    justify-content: space-between;
}

.laws-box>.leftpart {
    width: 397px;
}

.laws-box> .lawslist {
    display: inline-flex;
}

.laws-sec .lawslist {
    padding: 0;
    margin: 0;
}

.laws-sec .lawslist li {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    cursor: pointer;
    /* display: inline-block; */
    transition: all .3s ease;
    border-bottom: 2px solid transparent;
}

.laws-sec .lawslist li.active,
.laws-sec .lawslist li:hover {
    font-weight: 600;
    font-size: 22px;
    color: #0E8C43;
    transition: all .3s ease;
    border-bottom: 2px solid #0E8C43;
}


.lawslistdropdown {
    border: 1px solid #dedede;
    border-radius: 10px;   
    box-shadow:  0 0 10px #dedede;
    position: relative; 
    cursor: pointer; 
    
}

.lawslistdropdown::before{
    position: absolute;
    top: 10px;
    right: 10px;
    content:"";
    width: 10px;
    height: 15px;
    z-index: 99;
    transform: rotate(90deg);
}

.lawslistdropdown  h3 {
    font-size: 16px;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
    background-color: #fff;
    position: relative;
    z-index: 1;
   
}

.lawslistdropdown ul {
    position:absolute;
    border-radius: 10px;
    padding: 20px 0 0;
    margin: 0;
    top: 25px;
    left: 0;
    width: 100%;
    display: block;
    height: auto;
    border: 1px solid #dedede;
    z-index: 99;
}

.lawslistdropdown ul li {
    font-size: 16px;
    color: #000;
    font-size: 16px;
    padding: 5px 15px 5px 15px;
    background-color: white;
}

.lawslistdropdown ul li:hover {
    background-color: #d2d2d265;
    cursor: pointer;
}


.laws-box >.rightpart {
    width: 728px;
}
.laws-box >.rightpart > img {
    border-radius: 16px;
}

.laws-box >.rightpart .tab-content {
    background: #272974;
    padding: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    max-width: 73.5%;
    margin: -30% 0 auto auto;
    position: relative;
    z-index: 1;
    transition: all .3s ease;
    border-radius: 16px;
}

.laws-box >.rightpart .tab-content a {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #FFFFFF;
    text-decoration: none;
    margin-top: 32px;
}

.laws-box>.rightpart .tab-content a img {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 10px;
}


@media(max-width:1199px) {
    .bannerbox h2 {
        font-size: 30px;
    }

    .bannerbox>.leftpart {
        width: 50%;
        padding-right: 15px;
    }

    .bannerbox>.rightpart {
        width: 50%;
    }

    .compass-sec ul li {
        font-size: 20px;
    }

    .laws-box>.leftpart {
        padding-right: 10px;
    }

    .laws-sec .lawslist li {
        margin-bottom: 25px;
        font-size: 20px;
    }

    .compass-sec h3 {
        font-size: 26px;
    }




}


@media(max-width:991px){    
    
    .conductbanner_text h1 {
        max-width: 445px;
        font-size: 30px;    
        line-height: 46px;
    }    

    .conductbanner-sec {
        padding: 50px 0 105px;
    }
    
} 

@media(max-width:767px) {
    .banner-sec {
        margin: 35px 0 80px;
    }

    .bannerbox {
        width: 100%;
        flex-wrap: wrap;
    }

    .bannerbox>.leftpart {
        width: 100%;
    }

    .bannerbox>.rightpart {
        width: 100%;
        padding-left: 0;
    }

    .bannerbox h2 {
        font-size: 25px;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .compass-sec h3 {
        line-height: 1.5;
        margin-bottom: 20px;
        font-size: 20px;
    }

    .compass-sec .lawslist,
    .compass-sec ul {
        flex-wrap: wrap;
    }

    .compass-sec .lawslist li {
        width: 100%;
        font-size: 16px;
    }

    .compass-sec .lawslist li + li {
        margin-left: 0;
    }
    .compass-sec ul li {
        width: 100%;
    }
    .compass-sec ul li + li {
        display: block;
        margin-left: 0;
    }

    .laws-box {
        flex-wrap: wrap;
    }

    .laws-box > .leftpart {
        width: 100%;
        padding-right: 0;
    }

    .laws-box > .rightpart {
        width: 100%;
    }

    .laws-sec .lawslist li {
        display: table;
        max-width: 100%;
        margin-bottom: 20px;

    }
    .conductbanner_text {
        flex-wrap: wrap;
    }
    .conductbanner_text h1 {
        width: 100%;
        max-width: 100%;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 15px;
        text-align: center;
    }

    .conductbanner_text figure{
        width: 100%;
        max-width: 100%;
        padding-left: 0;
    }
    .laws-box>.rightpart .tab-content {
        max-width: 85% ;
    }
    .laws-sec{
        padding-top: 20px;
    }

    .laws-box >.rightpart .tab-content {
        margin: -60% 0 auto auto;
    }
   

}

@media(max-width:575px){

    .laws-box>.rightpart > img {
        width: 90%;
    }        
        
} 