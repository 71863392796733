.MapSize svg {
    height: 700px;
    position: relative !important;

}

/* .MapSize>svg>g :nth-child(4) {
    display: none !important;
} */

/* .MapSize>svg>g  :last-child  {
    display: none !important;
  } */

.HoverInfo {
    margin-top: 100px !important;
}

svg>#root-svg-group>g:nth-child(4) {

    display: none !important;

}

svg>#root-svg-group>g:nth-child(2)>:nth-child(2) {

    display: none !important;

}

svg>#root-svg-group>g:nth-child(2)>:nth-child(1) {

    display: none !important;

}

/* svg>#root-svg-group>g:nth-child(2)>:nth-child(30) {

    display: none !important;

} */

.presence_part {
    margin: 96px 0 50px;
    display: flex;
    justify-content: space-between;
}

.presence_part .left-part {
    width: 489px;
}

.presence_part .left-part h2 {
    font-size: 32px;
    font-weight: 600;
    color: #1E1E26;
    margin-bottom: 60px;
}

.presencemap_img {
    margin: 0 0 0 -40px;
}

.presence_part .right-part {
    width: calc(100% - 563px)
}

.presence_part .right-part>p {
    max-width: 100%;
    color: #4B4B51;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 33px;
    line-height: 24px;
}

.presence-part {
    display: flex;
    justify-content: space-between;
}

.presence-part>.presenceleft {
    width: 56.5%;
}

.presence-part>.presenceright {
    width: 41%;
    padding-left: 2%;
}

.presence_box {
    box-shadow: 0px 4px 20px 0px #0000000D;
    border: 1px solid #E8E8E9;
    padding: 24px;
    position: relative;
    border-radius: 20px;
}

.presence_box.presence_box1 {
    min-height: 362px;
    position: relative;
}

.presence_box h4 {
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    color: rgba(30, 30, 38, 1);
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(210, 210, 212, 1);
    margin-bottom: 25px;
}

.presence_box h4 strong {
    font-weight: 700;
    color: rgba(41, 40, 116, 1);
}

.presence_box h5 {
    font-size: 22px;
    color: rgba(30, 30, 38, 1);
    font-weight: 400;
    margin-bottom: 0;
    line-height: 31px;
    max-width: 200px;
    margin-bottom: 0;
}

.presence_box h5 b {
    font-weight: 600;
    color: rgba(41, 40, 116, 1);
}


.presence_box a {
    color: #0A8C44;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: inline-block;
    margin-top: 118px;
    padding-right: 15px;
    background-size: 7px;
}
.presence_box a > img {
    display: inline-block;
    margin: 0 0 0 10px;
}



.presence_img {
    position: absolute;
    right: 0px;
    bottom: 0px;
}


.presence_box2 h2 {
    text-transform: uppercase;
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #010101;
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
}


@keyframes textclip {
    to {
        background-position: 200% center;
    }
}


.presence_box2+.presence_box2 {
    margin-top: 25px;
}

.presence_box2 h2 {
    font-size: 14px;
    font-weight: 700;
    color: rgba(30, 30, 38, 1);
    margin-bottom: 0;  
}

.presence_box2 h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 60px;
    color: rgba(41, 40, 116, 1);
    margin-bottom: 0;    
}

.presence_box2>p {
    font-size: 16px;
    color: #4B4B51;
    margin-bottom: 0;
    line-height: 24px;
}


@media(max-width:1199px) {
    .presence_part .left-part {
        width: 389px;
    }

    .presence_part .right-part {
        width: calc(100% - 410px);
    }

    .presence_box {
        padding: 20px;
    }

    .presence_box2 h3 {
        font-size: 30px;
    }

    .presence_box a {
        margin-top: 20px;
    }

    .presence_box.presence_box1 {
        min-height: 355px;
    }

}

@media(max-width:991px) {
    .presence_box2 {
        margin-top: 25px;
    }
    .presence-part {
        display: block;
    }
    .presence-part>.presenceleft {
        width: 100%;
    }
    .presence-part>.presenceright{
        width: 100%;
        padding-left: 0;
    }

}

@media(max-width: 767px) {
    .presence_part {
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .presencemap_img {
        margin: 0 0 46px 0;
    }

    .presence_part .left-part {
        width: 100%;
    }

    .presence_part .right-part {
        width: 100%;
    }

    .presence_part .left-part h2 {
        text-align: center;
    }

    .presence_box2+.presence_box2 {
        margin-top: 20px;
    }

}