.portfoliobanner-sec {
    position: relative;
    margin: 0px 0 80px;
    overflow: hidden;
    padding-bottom: 30px;
    padding: 40px 0 250px;
    background: rgba(30, 30, 38, 1);

}

.portfoliobanner-sec .headingRout {
    color: #fff;
    margin-bottom: 44px;
}

.portfoliobanner-sec .headingRout span {
    color: #fff;
}

.portfoliobanner-sec .banner-part {
    max-width: 375px;
    margin-top: 190px;
}


.portfoliobanner-sec .banner-part h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    margin-bottom: 0px;
}

.portfoliobanner-sec .banner-part p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.transportationpart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
}

.transportationpart .leftpart {
    width: 587px;
}

.transportationpart h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(30, 30, 38, 1);
}

.transportationpart .rightpart {
    width: 585px;
    padding-left: 10px;
}

.transportationpart p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(75, 75, 81, 1);
}

.portfoliobus-box {
    background: #0E8C430D;
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 80px;
}

.inner-box {
    display: flex;
}

.portfoliobus-box .left-part {
    max-width: 750px;
    border-right: 1px solid #cdc6c6;
    padding-right: 35px;

}

.inner-box h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 48px;
}

.inner-box .bus-box {
    width: 100%;
    max-width: 248px;
}




 .innercity-box {
    display: flex;
}

.inner-box .bus-box figure {
    width: 100%;
    padding: 20px 10px;
    height: 105px;
    max-width: 248px;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}




.inner-box .left-part .bus-box figure img {
    height: 96px;
    object-fit: contain;
}

.inner-box .bus-box h3 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    line-height: 32px;
    background-color: #fff;
  
    height: 105px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    width: 100%;
    max-width: 248px;

}

.inner-box .bus-box p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(75, 75, 81, 1);
    margin-bottom: 0;
}

.inner-box .bus-box+.bus-box {
    margin: 0 0 0 25px;
}

.inner-box .right-part {
    margin-left: 35px;
}


.portfolioslider-sec {
    margin-bottom: 140px;
}

.portfolioslider-sec h2 {
    font-size: 32px;
    font-weight: 700;
    color: #1E1E26;
    margin-bottom: 40px;
}


.carousel-box {
    display: flex;
    justify-content: space-between;
}

.carousel-box>.left-part {
    width: 381px;
}

.carousel-box>.left-part>img {
    border-radius: 16px;
    width: 100%;
    height: 278px;
    object-fit: cover;
    object-position: center center;
}

.carousel-box>.right-part {
    width: calc(100% - 381px);
    padding-left: 32px;
}

.carousel-box>.right-part h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 18px 0 24px;
}
.carousel-box>.right-part h3 a{
font-size: 20px;
font-weight: 600;
line-height: 30px;
margin-top: 8px;
color: rgba(39, 41, 116, 1);
display: inline-block;


}

.carousel-box>.right-part h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 27px;
}

.carousel-controlbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.carousel-control-next,
.carousel-control-prev {
    position: static;
    background-color: red;
    color: #4B4B51;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 14px 0px #00000017;
    opacity: 1;
    width: 48px;
    height: 48px;
    margin: 0 0 0 16px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background: url(../../../assets/images/down-arrow.svg) top center no-repeat;
    /* background: url(`${process.env.REACT_APP_IMAGEBASE_URL}/portfolio1.jpg`) top center no-repeat; */

    background-size: contain;
    width: 19px;
    height: 14px;
}


.carousel-control-prev-icon {
    transform: rotate(90deg)
}

.carousel-control-next-icon {
    transform: rotate(-90deg)
}



/* /////////////////////////////// */

.portfoliobanner-sider {
    overflow: hidden;
    height: 100%;
}

.portfoliobanner-sec .portfoliobanner-sider .swiper {
    position: absolute;
    top: 132px;
    right: 0;
    width: calc(100% - 50%);
    /* padding-bottom: 60px; */
    height: auto;
}

.portfoliobanner-sec .swiper-slide {
    padding-left: 15px;
    transition: all .3s;
}


.portfoliobanner-sec .swiper-slide img {
    width: 87%;
    height: 100%;
    transition: all .3s;
    border-radius: 16px;
}


.portfoliobanner-sider .swiper-button-next,
.portfoliobanner-sider .swiper-button-prev {
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 14px 0px #00000017;
    border-radius: 12px;
    bottom: 0;
    background-color: #fff;
    right: 0;

    left: auto;
    top: auto;
    margin-right: 30px;
}


.portfoliobanner-sider .swiper-button-prev {
    margin: 40px 100px 0px 0px;
}

.portfoliobanner-sider .swiper-button-prev:after,
.portfoliobanner-sider .swiper-button-next:after {
    font-size: 18px;
    color: #4B4B51;
    font-weight: bold;

}

.portfoliobanner-sider .swiper-slide {
    height: 450px !important;
    transition: all .3s;
}

.portfoliobanner-sider .swiper-slide-active {
    /* width: 80% !important; */
    object-fit: contain;

}

.portfoliobanner-sider .swiper-slide-active img {
    transform: scale(1);
    transition: all .3s;
}

.portfoliobanner-sider .swiper-slide-next {
    transform: scale(1, .7) !important;
    transition: all .3s;
    top: -66px;
}



@media(max-width:1600px) {

    .portfoliobanner-sec .portfoliobanner-sider .swiper {
        width: calc(100% - 35%);
    }

 }



@media(max-width:1199px) {
    .portfoliobanner-sec .banner-part {
        max-width: 494px;
    }

    .inner-box .right-part {
        margin-left: 30px;
    }



    .carousel-box>.right-part h3 {
        font-size: 20px;
        line-height: normal;
        line-height: 30px;
    }

    .carousel-box>.right-part h4 {
        font-size: 16px;
    }
    .portfoliobanner-sec .portfoliobanner-sider .swiper {
        position: static;
        width: 100%;
    }
    .portfoliobanner-sec .banner-part {
        max-width: 100%;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .portfoliobanner-sec {
        margin: 35px 0 80px;
        padding: 40px 0 100px;
    }    

    .portfoliobanner-sider .swiper-button-next, .portfoliobanner-sider .swiper-button-prev {
        bottom: 20px;
    }

    .carousel-box>.right-part h3 a {
        font-size: 18px;
    }
    .footer-part > .left-part ul{
        padding-right: 0;
    }


}

@media(max-width:991px) {

    .portfoliobanner-sec .banner-part {
        max-width: 100%;
    }

    .portfoliobanner-sec .portfoliobanner-sider .swiper {
        position: static;
        width: 100%;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .portfoliobus-box {
        padding: 15px;
    }

    .inner-box {
        flex-wrap: wrap;
    }

    .portfoliobus-box .left-part {
        max-width: 100%;
        border-right : none;
        padding-right: 0;
        width: 100%;

    }

    .inner-box .right-part {
        margin-left: 0;
    }

    .inner-box h2 {
        font-size: 20px;
        margin-bottom: 20px;

    }

    .inner-box .left-part .innercity-box {
        border-right: none;
        padding-bottom: 25px;
        border-bottom: 1px solid #000;
        margin-bottom: 25px;
    }

    .inner-box .bus-box {
        max-width: 50%;
    }






}

@media(max-width:767px) {
    .portfoliobanner-sec .banner-part {
        max-width: 100%;
    }

    .portfoliobanner-sec .banner-part h2 {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 20px;
    }

    .portfoliobanner-sec {
        margin: 25px 0 60px;
    }

    .portfolioslider-sec h2 {
        font-size: 24px;
        margin-bottom: 25px;
    }

    .carousel-box {
        flex-wrap: wrap;
    }

    .carousel-box>.left-part {
        width: 100%;
        margin-bottom: 15px;
    }

    .carousel-box>.right-part {
        width: 100%;
        padding-left: 0;
    }

    .carousel-controlbtn {
        position: static;
        left: 0;
    }

    .portfoliobanner-sider .swiper-button-prev {
        margin: 20px 15px 0px 15px;
    }

    .carousel-box {
        padding: 0 10px;
    }

    .carousel-box>.left-part>img {
        height: 350px;
    }

    .portfoliobanner-sec .banner-part h1 {
        font-size: 25px;
        line-height: 35px;
    }
    
    .portfoliobanner-sider .swiper-button-prev {
        margin: 20px 95px 0px 25px;
    }
    
    .transportationpart{
        flex-wrap: wrap;
    }

    .transportationpart .leftpart {
        width: 100%;
    }
    .transportationpart .rightpart{
        width: 100%;
        padding-left: 0;
    }

    .inner-box .bus-box+.bus-box {
        margin: 0 0 0 25px;
    }
    
    .portfolioslider-sec {
        margin-bottom: 80px;
    }   

    .transportationpart h2 {
        font-size: 20px;
        line-height: 30px;
    }
        


}

@media(max-width:575px) {
    .innercity-box {
        flex-wrap: wrap;
    }

    .inner-box .bus-box,
    .inner-box .right-part {
        max-width: 100%;
        width: 100%;
    }

    .inner-box .bus-box h3 {
        font-size: 16px;
        line-height: normal;
    }

    .portfolioslider-sec h2 {
        font-size: 20px;
    }

    .inner-box .bus-box+.bus-box {
        margin: 15px 0px 0;
    }

    .portfoliobanner-sider .swiper-slide {
        height: 250px !important;
    }

    .portfoliobanner-sider .swiper-slide-next {
        top: -36px;
    }

    .bus-box figure,
    .inner-box .bus-box h3 {
        margin: 0 auto 15px;
    }


}