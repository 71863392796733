
footer{
   background: #1E1E26;
   padding: 40px 0 50px;
}

.footer-part {
   display: flex;
   justify-content: space-between;

}

.footer-part > .left-part { 
   width: 481px;
}

.footer-part > .left-part ul {
   padding-left: 0;
   margin-bottom: 0;   
   padding-right: 57px;
}

.footer-part > .left-part ul li {
   display: flex;
   align-items: flex-start;
}

.footer-part > .left-part ul li img { 

   display: inline-block;
   margin:5px 15px 0 0;
   vertical-align: middle;
}

.footer-part > .left-part ul li p,
.footer-part > .left-part ul li a {
   font-weight: 400;
   text-decoration: none;
   font-size: 16px;
   display: inline-block;
   vertical-align: top;
   width: calc(100% - 30px); 
   color: #FFFFFFCC;
   margin-bottom: 16px;
} 

.copyright-text{
   color: #FFFFFF;
   font-weight: 600;
   font-size:14px ;
   margin:-50px 0 0;
}

.footer-part > .right-part { 
   width: 587px;
}
textarea{
   min-height: 90px !important;
}

.footer-part > .right-part h2 {
   font-size: 18px;
   font-weight: 700;
   line-height: 28px;
   margin-bottom: 16px;
   color: #FFFFFF;
}

.footer-part > .right-part form label  {
   color: #78787D;
   font-size: 16px;
   margin-bottom: 10px;
   font-weight: 700;
}

.footer-part > .right-part form .form-control {
   color: #78787D;
   font-size: 14px;
   font-weight: 400;
   line-height: 22px;
   box-shadow: none;
   height: 42px;
}
.footer-part > .right-part form input[type=submit] {
   background: #0A8C44;
   padding: 10px 16px 10px 16px;
   border-radius: 40px;
   box-shadow: 0px 4px 12px 0px #00000014;
   color: #FFFFFF;  
   border: none;
   width: 285px;
}



@media(max-width:767px) { 
   .footer-part{
      flex-wrap: wrap;
   }
   .footer-part > .left-part {
      width: 100%;
   }
   .footer-part > .right-part {
      width: 100%;
      margin-top: 20px;
   }

   .footer-part > .left-part ul li img {
      margin-top: 7px;
   }
   .copyright-text {
      text-align: center;
      margin-top: 15px;
   }
}


@media(max-width:575px) {
   .footer-part > .right-part form input[type=submit] {
      width: 100%;
      margin-top: 20px;
   }

}



