

.emanagement-sec { 
    padding: 32px 0 100px;
    background: #1E1E26;
}

.emanagement-sec .headingRout {
    margin-bottom: 64px;
    color: #fff;
}

.emanagement-sec .headingRout span {
    color: #fff ;
} 


.emanagementbanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.emanagementbanner h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    margin: 0;
    max-width: 560px;
    padding-right: 10px;
}


.emanagementbanner .rightpart {
    max-width: 539px;
    padding-left: 10px;
}



.emanagement-sec figure {
    margin: 0;
}

.emanagement-sec figure img {
    width: 100%;
    border-radius: 16px;
    height: 100%;
}


.emanagementtext {
    display: flex;
    justify-content: space-between;
   padding:80px 0;
}

.emanagementtext h2 {
    width: 44%;
    padding-right: 10px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 0;
    color: #1E1E26;
}

.emanagementtext .rightpart {
    width: 50%;
    padding-left: 10px;
}


.emanagementtext .rightpart p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(30, 30, 38, 1);
}

.emanagementtext .rightpart a {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: rgba(10, 140, 68, 1);
    padding-right: 0;
    text-decoration: none;
}



.ewasteonebox {
    padding: 0;
    background: rgba(243, 249, 246, 1);
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 0px 0 60px;
}


.ewasteonebox .leftpart {
    width: 408px;
}

.ewasteonebox .leftpart img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.ewasteonebox .rightpart {
    width: calc(100% - 408px);
    padding: 30px 40px 30px 30px;
}


.ewasteonebox .rightpart p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(30, 30, 38, 1);
    margin-bottom: 25px;
}

.ewasteonebox .rightpart a {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: rgba(30, 30, 38, 1);
    display: inline-block;
    text-decoration: none;
    margin-top: 30px;
}
.ewasteonebox .rightpart a img {
    display: inline-block;
    margin: -5px 0 0 8px;
}




@media(max-width:1199px) {

    .emanagementbanner h1 {
        font-size: 32px;
        line-height: 50px;
    }
    .emanagement-sec .headingRout {
        margin-bottom: 44px;
    }

    .ewasteonebox .rightpart p {
        margin-bottom: 10px;
    }

    .ewasteonebox .rightpart a {
        margin-top: 15px;
    }

}

@media(max-width:991px) {
    .emanagement-sec{
        padding: 25px 0;
    } 

    .emanagementbanner {
        flex-wrap: wrap;
    }
    .emanagementbanner h1 {
        max-width: 100%;
        padding-right: 0;
        text-align: center;
    }
    .emanagementbanner .rightpart {
        max-width: 100%;    
        text-align: center;
        width: 100%;
    }
    .emanagementbanner .rightpart  figure {
        max-width: 500px;
        margin: 25px auto 0;
    }

    .emanagement-sec {
        padding: 70px 0;
    }

}


@media(max-width:767px) { 
    .emanagementtext,
    .ewasteonebox {
        flex-wrap: wrap;
    }

    

    .emanagementtext .rightpart {
        width: 100%;
        padding-left: 0;
    }

    .ewasteonebox .leftpart{
        width: 100%;
    }
    .ewasteonebox .rightpart{
        width: 100%;
        padding: 20px 15px;
    }
    .emanagementtext h2 {
        width: 100%;
        margin-bottom: 15px;
        padding-right: 0;
    }
    .emanagementbanner h1 {
        font-size: 25px;
        line-height: 40px;
    } 

}









