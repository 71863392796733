.aboutbanner-sec {
    background: rgba(30, 30, 38, 1);
    padding-top: 40px;
}

.aboutbanner-sec .headingRout,
.aboutbanner-sec .headingRout span {
    color: #fff;
}

.aboutbannerpart {
    position: relative;
    padding: 65px 0 178px;
}

.aboutbannerpart h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    width: 40%;
    position: relative;
    z-index: 1;

}

.aboutbannerpart figure {
    width: 70%;
    position: absolute;
    right: 0;
    top: 8%;
    z-index: 0;
}

.aboutbannerpart figure img {
    width: 100%;
}

.aboutteamsection {
    padding: 80px 0 100px;
}


.aboutteam_text h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: rgba(30, 30, 38, 1);
    margin: 0;
    margin-right: 12px;
}

.aboutteam_text p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(30, 30, 38, 1);
    margin: 24px 0 40px;
}

.aboutteam_text .btn {
    padding: 12px 46px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid rgba(232, 232, 233, 1);
    font-size: 16px;
    font-weight: 600;
    color: rgba(30, 30, 38, 1);
    line-height: 24px;
    cursor: none;
}



.aboutteam_text .active {
    background: rgba(10, 140, 68, 1);
    border: 1px solid rgba(10, 140, 68, 1);
    color: rgba(255, 255, 255, 1);
    transition: all .3s;
    cursor: none;
}


.teamlist {
    display: flex;
    margin: 45px auto;
    padding: 0;
    justify-content: space-evenly;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 40px;
    
}

.teamlist li {
    background: linear-gradient(0deg, #E8E8E9, #E8E8E9),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid rgba(232, 232, 233, 1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 16px;
    width: calc(75%/3 - 20px);
    /* margin-bottom: 40px; */
}


.teamlist figure {
    margin: 0;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    height: 233px;
}

.teamlist figure>img {
    width: 100%;
    height: 100%;
}

.coverImag{
    object-fit: cover;
}

.teamlist figure span {

    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    right: 0px;
    top: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamlist figure span img {
    width: 19px;
}

.teamlist h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(30, 30, 38, 1);
    margin: 16px 0 8px;
}

.teamlist h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    color: rgba(120, 120, 125, 1);
}



.aboutpart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
}

.aboutpart .leftpart {
    width: 484px;
}

.aboutpart h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(30, 30, 38, 1);
}

.aboutpart .rightpart {
    width: 690px;
    padding-left: 10px;
}

.aboutpart p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(75, 75, 81, 1);
}


.aboutteam-slider .swiper-slide img{
    width: 100% !important;
    height:500px !important;
    object-fit: cover;
}

.videoLink iframe{
    
    height:450px !important;
}


.aboutteam-slider .swiper-pagination {
    display: none;
}

/* .aboutteam-slider .swiper-scrollbar {

} */

.aboutteam-slider  .swiper{
    padding-bottom: 23px;
}


.aboutteam-slider .swiper-button-prev {
    left: auto;
    right: 90px;
    top: auto;
    bottom: 35px;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 15px;
    opacity: 1;
}

.aboutteam-slider .swiper-button-next  {    
    left: auto;
    right: 30px;
    top: auto;
    bottom: 35px;
    width: 50px;
    height: 50px;
    background: #fff;
    opacity: 1;
    border-radius: 15px;
}

.aboutteam-slider .swiper-button-prev:after {
    font-size: 25px;
}

.aboutteam-slider .swiper-button-next:after {
    font-size: 25px;
}

.aboutteam-slider .swiper-horizontal > .swiper-scrollbar, 
.aboutteam-slider .swiper-scrollbar.swiper-scrollbar-horizontal {
    background: rgba(10, 140, 68, 0.3);
    height: 12px;
    border-radius: 0;
}

.aboutteam-slider .swiper-scrollbar-drag {
    background: rgba(10, 140, 68, 1);
    border-radius: 0;
    transform: translate3d(1076.4px, 0px, 0px);
    transition-duration: 0ms;
    width: 358.8px;
    height: 12px;
}



@media(max-width:1199px) {
    .aboutbannerpart h1 {
        font-size: 30px;
        line-height: 45px;
    }

    

}


@media(max-width:991px) {
    .aboutbannerpart h1 {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .aboutbannerpart figure {
        position: relative;
        margin: 30px auto 0;
        width: 85%;
    }

    .aboutbannerpart {
        padding: 15px 0 95px;
    }
    .teamlist {
        justify-content: space-evenly;
    }

    .teamlist li {
        width: calc(80%/2 - 26px);  
        /* margin-left: 39px;  */
    }
    .teamlist li:nth-child(3n+1){
        margin-left: 0;
    }


}

@media(max-width:767px) {
    .aboutbannerpart h1 {
        font-size: 25px;
        line-height: 40px;
    }

    .teamlist li {
        width: calc(95%/2 - 10px);
        /* margin-left: 15px; */
        padding: 16px;
        /* margin-bottom: 15px; */
    }
    .teamlist figure {
        margin: 0;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        height: 233px;
    }

    .teamlist h3 {
        font-size: 18px;
    }  
    
    .aboutteamsection {
        padding: 50px 0 50px;
    }   
    
    .aboutpart{
        flex-wrap: wrap;
    }

    .aboutpart .leftpart {
        width: 100%;
    }
    .aboutpart .rightpart{
        width: 100%;
        padding-left: 0;
    }

}



@media(max-width:399px) { 
    .teamlist li {
        width: 100%;
        margin-left :0;
    }
    .teamlist figure{
        text-align: center;
        border-radius: 0;
    }
    .teamlist figure>img {
        max-width: 220px;
        margin: 0 auto;
        border-radius: 21px;
    }
    
}



