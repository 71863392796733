.newsbanner-sec {
    padding: 84px 0;
    /* background: url(../../../assets/images/newsbannerbg.png) top center no-repeat; */
    background: top center no-repeat;
    background-size: 100% 100%;
}


.newsbanner-sec .headingRout {
    color: rgb(255 255 255 / 50%);
}

.newsbanner-sec .headingRout span {
    color: #fff;
}

.newsbanner-sec h1 {
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    margin: 48px 0 24px;
}

.newsbanner-sec p {
    color: #FFFFFF;
    max-width: 499px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
}

.message-sec {
    padding: 102px 0 40px;
}

.message-sec .container {
    max-width: 1024px !important;
}


.message-box {
    border: 1px solid #D2D2D4;
    border-radius: 16px;
    padding: 40px 40px 30px;
}

.message-box nav {
    display: flex;
    justify-content: center;
}


.message-box h2 {
    color: #1E1E26;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin-bottom: 37px;
}

.message-box .nav-tabs {
    border: none;
    margin-bottom: 15px;
}

.message-box .nav-tabs .nav-link {
    padding: 16px 88px;
    border: none;
    color: rgba(165, 165, 168, 1);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    border-bottom: 2px solid rgba(210, 210, 212, 1);
    transition: all .3s ease;
}

.message-box .nav-tabs .nav-link.active {
    transition: all .3s ease;
    color: rgba(14, 140, 67, 1);
    border-bottom: 3px solid rgba(14, 140, 67, 1);
}


.message-box .tab-content label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(120, 120, 125, 1);
    margin-bottom: 8px;
}

.message-box .tab-content .form-control,
.message-box .tab-content .form-select {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    border: 1px solid rgba(210, 210, 212, 1);
    color: rgba(120, 120, 125, 1);
    position: relative;
}



.message-box .tab-content .btn {
    padding: 10px 16px 10px 16px;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    background: rgba(10, 140, 68, 1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    color: #ffff;
    width: 285px;
    height: 48px;
}

.message-box .tab-content .file-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding: 16px 40px 16px 20px;
    border-radius: 8px;
    border: 1px solid rgba(210, 210, 212, 1);
    color: rgba(120, 120, 125, 1);
    position: relative;
    height: 62px;
    overflow: hidden;

} 

.message-box .tab-content .file-box .uploadinfo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    color: #828282;
    padding:15px;
    z-index:-1;
}


.message-box .tab-content .file-box:before{
    content: "";
    background:url(../../../assets/images/paperclip.svg) top center no-repeat ;
    background-size: contain;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 0;
    cursor: pointer;
}

.fileclass{
    cursor: pointer;
}
.message-box .tab-content .file-box > input {
    touch-action: manipulation;
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    margin-left: -231px;
    border: none;
    padding-left: 0;
    padding-top: 5px;
    width: 150%;
    height: 100%;
}



.message-box .tab-content input.form-control[type=file]:after {
    content: "";
    top: 50%;
    right: 10px;
    display: block;
    position: absolute;
    background: url(../../../assets/images/paperclip.svg) top center no-repeat ;
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-top: -11px;

}


.addres-box {
    background: rgba(14, 140, 67, 0.05);
    padding: 38px 40px;
    border-radius: 16px;
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
}

.addres-box .leftpart {
    width: 433px;
    padding-right: 40px;
}

.addres-box .leftpart h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: rgba(75, 75, 81, 1);


}

.addres-box .rightpart {
    padding-left: 40px;
    width: calc(100% - 433px);
}

.addres-box .rightpart p a {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0;
    text-decoration: none;
    color: rgba(75, 75, 81, 1);
}

.map-sec iframe {
    border-radius: 16px;
    width: 100%;
    height: 450px;
}



@media(max-width:991px) {

    .addres-box .leftpart {
        width: 50%;
        padding-right: 15px;
    }

    .addres-box .rightpart {
        width: 50%;
        padding-left: 15px;
    }



}


@media(max-width:767px) {
    .addres-box {
        flex-wrap: wrap;
    }

    .addres-box .leftpart {
        width: 100%;
        padding-right: 0;
    }

    .addres-box .rightpart {
        width: 100%;
        padding-left: 0;
    }

    .addres-box .rightpart p {
        margin-bottom: 0;
    }

    .addres-box .leftpart h2,
    .addres-box .rightpart p a {
        font-size: 14px;
    }


    .message-box .nav-tabs .nav-link {
        padding: 10px 20px;
    }

    .message-box h2 {
        font-size: 20px;
        line-height: 1;
        margin-bottom: 25px;
    }

    .message-box {
        padding: 10px !important;
    }


}

@media(max-width:579px) {
    .message-box .nav-tabs .nav-link {
        padding: 10px;
    }
}



