@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');



header .container {
  max-width: 1256px !important;
}
header .logo {
  width: 184px;
  cursor: pointer;
}
header .logo2 {
  width: 100px;
  
}

.navbar {  
  background-color: white;
  position: relative;
  padding: 10px 0;
}

.menu-icon, .HamburgerClose-btn {
  display: none;
}

.nav-elements > ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-elements > ul > li {
  padding-bottom: 10px;
}
.nav-elements ul > li > a {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #1E1E26;
  text-decoration: none;
}

.nav-elements > ul > li + li {
  margin-left: 40px;
}

.nav-elements >ul> li> a.active {
  font-weight: 600;
  color:  #0A8C44;
}

.nav-elements > ul > li.dropdown-bar{
  position: relative;
}

.nav-elements > ul > li.dropdown-bar > a > img{
  width: 14px;
}

.nav-elements > ul > li > .dropdown-menu {
  /* display: block; */
  box-shadow: 0px 4px 20px 3px #0000000D;
  color: #fff;
  padding: 18px;
  background-color: #fff;
  border: none;
  top: 30px;
  width: 235px; 
  height: 0;
  transition: all .3s;
}
.nav-elements > ul > li.dropdown-bar:hover .dropdown-menu {
  display: block;
  height:100px ;
  transition: all .3s;
}

.nav-elements > ul > li > .dropdown-menu > li > a{
  color: #292874;
  font-size: 14px;
  font-weight: 400;
}

.nav-elements > ul > li > .dropdown-menu > li + li {
  margin-top: 6px;
}
.headerBlock{
  display: block !important;
}

@media(max-width:991px) {
  .nav-elements > ul > li + li {
    margin-left: 30px;
  }
}

.arrow-icon {
  transition: transform 0.3s ease-in-out;
}

.arrow-icon.down {
  transform: rotate(90deg);
}


@media(max-width:767px) {
    .menu-icon,
    .HamburgerClose-btn {
      display: block;
    }
    .headerBlock{
      display: none !important;
    }
    .nav-elements{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #00000080; 
      height: 100%;
      /* border-left: 83px solid #00000080; */
      z-index: 9999;
      /* display: none; */
      padding: 0 0 0 0px;
      overflow: auto;
      width: 100%;
      transition: all .3s ease;
    }
    
    .nav-elements > ul {
      display: block;
      background-color: #F1F9F4; 
      padding: 90px 0 30px 32px;
      width: 100%;
      height: 100%;
      max-width: 260px;
      margin-right: 0;
      margin-left: auto;
    }
    
    .nav-elements > ul > li + li {
      margin-left: 0;
      margin-top: 30px;
    }

    .HamburgerClose-btn {
      border: none;
      position: absolute;
      top: 23px;
      right: 21px;
      outline: none;
      background-color: transparent;
    }

    .nav-elements ul > li > a {
      color: #292874;
      font-size: 18px;
      font-weight: 600;
    }

    .nav-elements > ul > li.dropdown-bar > a > img {
      width: 10px;
    }

    .nav-elements > ul > li > .dropdown-menu {
      display: block;
      background-color: #0000;
      box-shadow: none;
      position: static;
    }


  .navbar{
    padding: 8px 0;
  }

  .nav-elements > ul > li > .dropdown-menu > li > a {
    color: #1E1E26;
  }

  .nav-elements > ul > li > .dropdown-menu {
    padding: 10px;
  }
  .nav-elements > ul > li > .dropdown-menu > li + li{
    margin-top: 10px;
  }

}