@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    line-height: 1;
    overflow-x: hidden;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul,li,ol {
    list-style-type: none;
    padding: 0;
    padding-left: 0;
    margin: 0;
}

p {
    line-height: 24px;
}


.container {
    max-width: 1230px !important;
} 

.headingRout {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 40px;
}

.headingRout span {
    font-size: 14px;
    font-weight: 700;
    color: #272974;
}


/* html
  {

   position: relative;

   height: 100%;

 }
 #root {

    height: 100%;
 
  }

  body {

    position: relative;
 
    height: 100%;
 
  }
 
 
 
  body {
 
    background: #eee;
 
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
 
    font-size: 14px;
 
    color: #000;
 
    margin: 0;
 
    padding: 0;
 
  } */
  #root {
    height: 100%; 
  }
  html ,
  body ,#app1 {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #2e2b2b;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }